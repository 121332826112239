import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default () => {
	const section = document.querySelector('.hero');
	const letters = document.querySelectorAll('.hero .hero__aside-letter');

	if (section && letters.length) {
		// console.log('hero');
		const items = gsap.utils.toArray(letters).reverse();
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				start: "top 0",
				// end: "bottom 10%",
				end: "bottom 10%",
				yoyo: true,
				scrub: true,
				onUpdate: (_this)=>{
					// console.log(_this);
				}
			}
		});

		items.forEach(function(letter, index) {
			tl.to(letter, {
				yPercent: -25,
				yoyo: true,
				duration: 1,
				// stagger: 0.01,
			}, index);

			tl.to(letter, {
				yPercent: 0,
				yoyo: true,
				duration: 1,
				// stagger: 0.01,
			}, index+2);
		});
		
	}



	// text animation
	const wordEl = document.querySelector('.hero__word');
	// console.log(wordEl);

	if (wordEl) {
		const words = wordEl.dataset.words.split('|');

		if (words.length) {
			swapWords(words);
		}
	}


	function swapWords(words, cursor = 0) {
		const wordOld = document.querySelector('.hero__word-old');
		const wordNew = document.querySelector('.hero__word-new');
		const stepPos = '<+0.1';
		// const stepPos = '<';
		const DURATION = 1.5;
		const DELAY = 1;

		wordNew.innerText = words[cursor];

		// console.log(wordOld);
		// return;

		const tl = gsap.timeline({
			onComplete: ()=>{
				// console.log('complete')
				wordNew.classList.remove('hero__word-new');
				wordNew.classList.add('hero__word-old');

				wordOld.classList.remove('hero__word-old');
				wordOld.classList.add('hero__word-new');

				gsap.set([wordNew,wordOld], {
					clearProps: "all"
				});

				let c = cursor + 1;
				if (c > (words.length-1)) {
					c = 0;
				}
				// console.log(this);
				// console.log({c});
				// console.log('going to next iteration');

				setTimeout(function(){
					swapWords(words, c);
				}, DELAY*1000);
				
			}
		});

		tl.fromTo(wordOld, {
			y: "0%",
			// opacity: 1
		},{
			y: "-100%",
			// opacity: 0.5,
			duration: DURATION
		});

		tl.fromTo(wordNew, {
			y: '50%',
			// opacity: 0.5
		}, {
			y: '-50%',
			// opacity: 1,
			duration: DURATION
		}, stepPos);
	}

}
