import { Splide } from '@splidejs/splide';

export default () => {
	const slider = document.querySelector('.reviews .splide');

	if (slider) {
		const splide = new Splide( slider, {
			type   : 'loop',
			// focus  : 0,
			pagination: true,
			autoWidth: true,
			arrows: false,
			speed: 500,
			drag: true,
			autoplay: true,
		}).mount();
	}
}



