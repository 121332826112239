// import MicroModal from 'micromodal';
export default () => {
	const modal = document.querySelector('.modal');
	const buttons = document.querySelectorAll('[data-open-popup]');

	if (modal && buttons.length) {
		buttons.forEach(function(button) {
			button.addEventListener('click', (e) => {
				e.preventDefault();
				const fieldProduct = document.querySelector('.js-order-type');

				// console.log({fieldProduct});

				if (fieldProduct) {
					fieldProduct.value = button.dataset.product;
				}

				modal.classList.add('modal--visible');
			});
		});

		const closeBtns = modal.querySelectorAll('.js-modal-close');
		closeBtns.forEach(function(btn) {
			btn.addEventListener('click', (e) => {
				e.preventDefault();

				modal.classList.remove('modal--visible');
			});
		});
	}



	// form
	const form = document.getElementById("form");
	// const result = document.getElementById("result");

	form.addEventListener("submit", function (e) {
		e.preventDefault();
		
		const formData = new FormData(form);
		const object = Object.fromEntries(formData);
		const json = JSON.stringify(object);

		modal.classList.add('modal--loading');
		
		// result.innerHTML = "Please wait...";

		fetch("https://api.web3forms.com/submit", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: json,
		})
			.then(async (response) => {
				let json = await response.json();

				modal.classList.remove('modal--loading');

				if (response.status == 200) {
					modal.classList.add('modal--thank');

					if (typeof gtag == 'function') {
						gtag('event', 'lead');
						console.log('GA event sent');
					}
					if (typeof fbq == 'function') {
						fbq('track', 'Purchase');
						console.log('FB event sent');
					}
					
					// result.innerHTML = json.message;
					// result.classList.remove("text-gray-500");
					// result.classList.add("text-green-500");
				} else {
					console.log(response);
					// result.innerHTML = json.message;
					// result.classList.remove("text-gray-500");
					// result.classList.add("text-red-500");
				}
			})
			.catch((error) => {
				console.log(error);
				modal.classList.remove('modal--loading');
				// result.innerHTML = "Something went wrong!";
			})
			.then(function () {
				form.reset();

				modal.classList.remove('modal--loading');

				setTimeout(() => {
					modal.classList.remove('modal--thank');
					// result.style.display = "none";
				}, 5000);
			});
	});
}
