import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import BiggerPicture from 'bigger-picture';
// https://github.com/henrygd/bigpicture

export default () => {
	const sections = document.querySelectorAll('.product');
	
	if (sections.length) {
		sections.forEach(function(section) {
			const overlayText = section.querySelector('.product__title-overlay');

			if (!overlayText) {return};

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: section,
					start: "top 20%",
					end: "bottom 80%",
					scrub: true,
					// markers: true,
					// onUpdate: (_this)=>{
					// 	console.log(_this);
					// }
				}
			});

			tl.fromTo(overlayText, {
				'clip-path': 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
			}, {
				'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
				duration: 10
			});
			// console.log(tl);

			let bp = BiggerPicture({
				// target: section,
				target: document.body,
			});

			let items = section.querySelectorAll('a.product__figure');
			// console.log(items);

			items.forEach(function(element) {
				element.addEventListener('click', (e) => {
					e.preventDefault();

					// console.log('click');
					// console.log(e.currentTarget);

					// console.log(element);
					bp.open({
						items: items,
						el: element
					});
				});
			});
		});
	}

	
	// image reveal
	const rows = document.querySelectorAll('.product__gallery-grid');

	return;
	
	rows.forEach(function(row) {
		const tl = gsap.timeline({
			// paused: true
		});

		let pictures = row.querySelectorAll('.product__figure');
		// console.log(pictures);

		tl.fromTo(pictures, {
			opacity: 0,
			yPercent: 10,
			ScrollTrigger: {
				target: row, 
			}
		}, {
			opacity: 1,
			yPercent: 0,
			duration: 0.6,
			ease: 'power2.out',
			stagger: 0.2
		});

		// tl.fromTo(pictures[0], {
		// 	opacity: 0,
		// 	yPercent: 10
		// }, {
		// 	opacity: 1,
		// 	yPercent: 0,
		// 	duration: 0.5
		// });

		// tl.fromTo(pictures[1], {
		// 	opacity: 0,
		// 	yPercent: 50,
		// 	xPercent: -150,
		// 	scale: 1.5
		// }, {
		// 	opacity: 1,
		// 	yPercent: 0,
		// 	xPercent: 0,
		// 	scale: 1,

		// 	duration: 0.3
		// });

		// tl.fromTo(pictures[2], {
		// 	opacity: 0,
		// 	yPercent: -50,
		// 	xPercent: -150,
		// 	scale: 1.5
		// }, {
		// 	opacity: 1,
		// 	yPercent: 0,
		// 	xPercent: 0,
		// 	scale: 1,

		// 	duration: 0.3
		// }, '<');

		
		
	});
}


